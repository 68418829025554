<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getConfigJson()
  }
}
</script>

<style lang="scss">
html,body{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
html{
  font-size:62.5%;
} 
#app{
  width: 100%;
  height: 100%;
}
</style>

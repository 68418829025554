import axios from 'axios'

// 创建一个axios实例
// const http = axios.create()
const http = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.NODE_ENV == 'development' ? '' : localStorage.getItem("api_url"),
  // 超时
  timeout: 10000
})

// axios.defaults.baseURL = '/api'


// 添加请求拦截器
http.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8'
//   let token = localStorage.getItem("token") 
//   if(token) {
//     config.headers['Authorization'] = token;
//   }
  return config
},err=> {
return Promise.reject(err);
})

// 添加相应拦截器
http.interceptors.response.use(response => {
    console.log('response', response.data);
 const res = response.data;
//如果返回的状态码不是200就主动报错
return res;
// if(res.code === 200  && res ){
//     return res;
// }else{
//     //   return Promise.reject(res.msg || 'error');
//   }
},error => {
//返回接口的错误信息
return Promise.reject(error);
})

export default http

<template>
  <div class="container">
    <div class="bg-img"  v-if="!show">
      <h4 class="title">校园招聘宝</h4>
      <v-button @click="openCamera" type="info" class="v-button" icon="scan">扫码签到</v-button>
    </div>
    <div class="wrapper" v-if="show">
      <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
       <div class="qr-scanner" v-if="!validationSuccess">
        <div class="scan-txt">
              扫描二维码
          </div>
          <div class="box">
              <div class="line"></div>
              <div class="angle"></div>
          </div>
          <div class="txt">
              将二维码放入框内，即可自动扫描
          </div>
      </div>
    </qrcode-stream>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {

  components: { QrcodeStream },

  data () {
    return {
      isValid: undefined,
      camera: 'auto',
      result: null,
      show: false
    }
  },

  computed: {
    validationPending () {
      return this.isValid === undefined
        && this.camera === 'off'
    },

    validationSuccess () {
      return this.isValid === true
    },

    validationFailure () {
      return this.isValid === false
    }
  },
  methods: {
   handleSignIn(data) {
    this.$http({
      method: "post",
      // url: 'http://192.168.3.215:8080/system/zpbjobfairsignin',
      url: '/system/zpbjobfairsignin',
      data: {
        'data': data
      }
      }).then(res => {
        console.log('res=', res.msg)
        if (res.code === 200) {
           this.$toast({
            duration: 2000,
            message: res.msg
          })
          this.show = false
        } else {
          this.$toast({
            duration: 2000,
            message: res.msg
          })
          this.show = false
        }
      })
   }, 
   openCamera() {
      this.show = true
   },
    onInit (promise) {
     console.log('promise=', promise);
      promise
        .catch(console.error)
        .then(this.resetValidationState)
    },

    resetValidationState () {
      this.isValid = undefined
    },

    async onDecode (content) {
      console.log('content=', content);
      this.result = content
      this.handleSignIn(content)
      this.turnCameraOff()

      // pretend it's taking really long
      await this.timeout(2000)
      console.log('isValid1=', this.isValid);
      this.isValid = content.startsWith('https') // 网站
      // replace(reg,'https://');
      console.log('isValid2=', this.isValid);


      // if (this.isValid) {
      //   console.log('----------------------');
      //   window.location.href = content
      //   // window.open('https://www.baidu.com', '_blank')
      //   // let url = this.$router.resolve({
      //   //   path: 'https://www.baidu.com'
      //   // })
      //   // console.log(url.href.substr(1));
      //   // window.open(url.href.substr(1), '_blank')
      // } else {
      //   console.log(this);
      //   this.$toast.success('签到成功')
      //   this.show = false
      //   // this.$toast.fail('失败文案')
      //   // this.Toast.success('成功文案');
      // }

      // some more delay, so users have time to read the message
      await this.timeout(3000)

      this.turnCameraOn()
    },

    turnCameraOn () {
      this.camera = 'auto'
    },

    turnCameraOff () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    }
  }
}
</script>

<style scoped>
.container{
  width: 100%;
  height: 100%;
  background: #fff;
}
.bg-img{
  width: 100%;
  height: 100%;
  background: url('../../public/img/bg_1@2x.png') no-repeat center center;
  background-size: 100% 100%;
}
.title{
  font-size: 45px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.v-button{
  /* margin: auto; */
   /* position: absolute;
  top: 50%;
  transform: translateY(-50%); */
  width: 60%;
   position: absolute;
  left: 50%;
  top: 65%;
  transform: translateX(-50%);
  border-radius: 10px;
  background-color: #0D7CFF;
}
.wrapper{
  /* height: 100vh; */
  width: 275px;
  height: 275px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  /* font-size: 1.4rem; */
  font-size: 1.6rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
.qr-scanner {
  background-image: linear-gradient(
          0deg,
          transparent 24%,
          rgba(32, 255, 77, 0.1) 25%,
          rgba(32, 255, 77, 0.1) 26%,
          transparent 27%,
          transparent 74%,
          rgba(32, 255, 77, 0.1) 75%,
          rgba(32, 255, 77, 0.1) 76%,
          transparent 77%,
          transparent
      ),
      linear-gradient(
          90deg,
          transparent 24%,
          rgba(32, 255, 77, 0.1) 25%,
          rgba(32, 255, 77, 0.1) 26%,
          transparent 27%,
          transparent 74%,
          rgba(32, 255, 77, 0.1) 75%,
          rgba(32, 255, 77, 0.1) 76%,
          transparent 77%,
          transparent
      );
  background-size: 3rem 3rem;
  background-position: -1rem -1rem;
  width: 100%;
  height: 100%;
  /* height: 100vh; */
  /* height: 288px; */
  position: relative;
  background-color: #1110;

  /* background-color: #111; */
}
/* .qrcode-stream-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 82px;
  clear: both;
} */
/* .qrcode-stream-wrapper >>> .qrcode-stream-camera {
  width: 213px;
  height: 210px;
  clear: both;
  margin-top: 39px;
} */
.qr-scanner .box {
   z-index: 10;
  width: 275px;
  height: 275px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  overflow: hidden;
  /* border: 0.1rem solid rgba(0, 255, 51, 0.2); */
  border: 0.1rem dashed #0D7CFF;
  box-sizing: border-box;
  /* background: url('http://resource.beige.world/imgs/gongconghao.png') no-repeat center center; */
}
.qr-scanner .scan-txt {
  width: 100%;
  font-size: 25px;
  text-align: center;
  color: #000;
  margin: 0 auto;
  position: absolute;
  left: 0;
  top: -78px;
}
.qr-scanner .txt {
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 15px;
  text-align: center;
  /* color: #f9f9f9; */
  color: #666;
  margin: 0 auto;
  position: absolute;
  bottom: -61px;
  left: 0;
  z-index: 10;
}
.qr-scanner .myQrcode {
  text-align: center;
  color: #00ae10;
}
.qr-scanner .line {
  height: calc(100% - 2px);
  width: 100%;
  /* background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #00ff33 211%); */
   background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #0D7CFF 211%);
  /* border-bottom: 3px solid #00ff33; */
  border-bottom: 3px solid #0D7CFF;
  transform: translateY(-100%);
  /* animation: radar-beam 2s infinite alternate; */
  animation: radar-beam 2s infinite;
  animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
  animation-delay: 1.4s;
}

.qr-scanner .box:after,
.qr-scanner .box:before,
.qr-scanner .angle:after,
.qr-scanner .angle:before {
  content: '';
  display: block;
  position: absolute;
  width: 3vw;
  height: 3vw;

  border: 0.2rem solid transparent;
}

.qr-scanner .box:after,
.qr-scanner .box:before {
  top: 0;
  /* border-top-color: #00ff33; */
  border-top-color: #0D7CFF;
}

.qr-scanner .angle:after,
.qr-scanner .angle:before {
  bottom: 0;
  border-bottom-color: #0D7CFF;
}

.qr-scanner .box:before,
.qr-scanner .angle:before {
  left: 0;
  border-left-color: #0D7CFF;
}

.qr-scanner .box:after,
.qr-scanner .angle:after {
  right: 0;
  border-right-color: #0D7CFF;
}

@keyframes radar-beam {
  0% {
      transform: translateY(-100%);
  }

  100% {
      transform: translateY(0);
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Button from 'vant/lib/button'
// import Toast from 'vant/lib/toast'
import { Toast } from 'vant'
import 'vant/lib/button/style'
import 'vant/lib/toast/style'
import http from '@/utils/request'
import axios from 'axios'

Vue.component('v-button', Button)
Vue.use(Toast)
Vue.prototype.$http = http
Vue.prototype.getConfigJson = function () {
  if (!localStorage.getItem("api_url")) {
    axios.get("./config.json").then(res => {
      console.log(res)
      localStorage.setItem("api_url", res.data.SERVER_API)
      this.$router.go(0)

      return res.data.SERVER_API
    })
  }
}
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
